//import gql from "graphql-tag"

export const GET_WELCOME_WIZARD_DATA = /* GraphQL */ `
  query GetWelcomeWizardData($username: String!) {
    getUserByUsername(username: $username) {
      id
      firstName
      lastName
      birthDate
      gender
      displayName
      country
      declaredOrigin
    }
  }
`

export const UPDATE_WELCOME_WIZARD_DATA = /* GraphQL */ `
  mutation UpdateWelcomeWizardData(
    $id: String!
    $firstName: String
    $lastName: String
    $displayName: String!
    $birthDate: String
    $gender: String
    $country: String!
    $declaredOrigin: String!
  ) {
    updateUser(
      updateUserInput: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        displayName: $displayName
        birthDate: $birthDate
        gender: $gender
        country: $country
        declaredOrigin: $declaredOrigin
      }
    ) {
      id
    }
  }
`

export const GET_PERSONAL_DETAILS = /* GraphQL */ `
  query GetPersonalDetails($id: String!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      birthDate
      birthPlace
      taxCode
      gender
      displayName
      email
      mobile
      roles
      address1
      city
      zipCode
      state
      country
      firstLoginDate
      acptProfiling
      updtProfiling
      acptMarketing
      updtMarketing
      acptNewsletter
      updtNewsletter
      acptStatute
      updtStatute
      subscribedSince
      subscribedUntil
      stripeCustomerId
      declaredOrigin
    }
  }
`

export const UPDATE_PERSONAL_DETAILS = /* GraphQL */ `
  mutation UpdatePersonalDetails(
    $id: String!
    $firstName: String
    $lastName: String
    $displayName: String
    $birthDate: String
    $birthPlace: String
    $gender: String
    $mobile: String
    $address1: String
    $city: String
    $zipCode: String
    $state: String
    $country: String
    $acptProfiling: Int
    $acptMarketing: Int
    $acptNewsletter: Int
    $acptStatute: Int
    $taxCode: String
    $declaredOrigin: String
  ) {
    updateUser(
      updateUserInput: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        displayName: $displayName
        birthDate: $birthDate
        birthPlace: $birthPlace
        gender: $gender
        mobile: $mobile
        address1: $address1
        city: $city
        zipCode: $zipCode
        state: $state
        country: $country
        acptProfiling: $acptProfiling
        acptMarketing: $acptMarketing
        acptNewsletter: $acptNewsletter
        acptStatute: $acptStatute
        taxCode: $taxCode
        declaredOrigin: $declaredOrigin
      }
    ) {
      id
    }
  }
`

export const GET_ALL_MY_GROUPS = /* GraphQL */ `
  query GetAllMyGroups($id: String!) {
    getUser(id: $id) {
      UserInBuyingGroups {
        id
        disableNotifications
        registrationDate
        participationDate
        choosingDate
        notInterestInBuying
        confirmationDate
        definitelyNotInterestedInBuying
        BuyingGroup {
          id
          name
          uidCMS
          pubblicationStartDate
          pubblicationEndDate
          registrationStartDate
          registrationEndDate
          participationStartDate
          participationEndDate
          choosingStartDate
          choosingEndDate
          confirmationStartDate
          confirmationEndDate
          resellerFlowStartDate
          resellerFlowEndDate
          uniteChooseAndConfirm
        }
      }
      Organization {
        name
        ResellerIn {
          BuyingGroup {
            id
            name
            uidCMS
            pubblicationStartDate
            pubblicationEndDate
            registrationStartDate
            registrationEndDate
            participationStartDate
            participationEndDate
            choosingStartDate
            choosingEndDate
            confirmationStartDate
            confirmationEndDate
            resellerFlowStartDate
            resellerFlowEndDate
          }
        }
        OrganizedBuyingGroups {
          id
          name
          uidCMS
          pubblicationStartDate
          pubblicationEndDate
          registrationStartDate
          registrationEndDate
          participationStartDate
          participationEndDate
          choosingStartDate
          choosingEndDate
          confirmationStartDate
          confirmationEndDate
          resellerFlowStartDate
          resellerFlowEndDate
        }
      }
    }
  }
`

export const GET_ALL_GROUPS = /* GraphQL */ `
  query GetAllGroups {
    searchBuying_groups {
      id
      name
      participationStartDate
      pubblicationEndDate
      participationEndDate
      pubblicationStartDate
      registrationEndDate
      registrationStartDate
      resellerFlowEndDate
      resellerFlowStartDate
      uidCMS
      uniteChooseAndConfirm
      declination
      crmId
      choosingEndDate
      choosingStartDate
      confirmationEndDate
      confirmationStartDate
      Resellers {
        Organization {
          name
          notificationsEmail
          notifyOnEachSell
        }
      }
      GoodsOrServices {
        GoodOrService {
          name
          GoodOrServiceType {
            name
          }
        }
      }
    }
  }
`

// export const CREATE_ORGANIZATION_TYPE = /* GraphQL */ `
//   mutation createOrganizationType($name: String!, $roles: String!) {
//     createOrganization_type(createOrganization_typeInput: { name: $name, roles: $roles }) {
//       id
//     }
//   }
// `

// export const LIST_ORGANIZATION_TYPES = /* GraphQL */ `
//   query listOrganizationTypes {
//     listOrganization_types {
//       id
//       name
//       roles
//     }
//   }
// `

export const getUserIdByIdentityId = /* GraphQL */ `
  query GetUserIdByIdentityId($identityId: String!) {
    getUserByIdentityId(identityId: $identityId) {
      id
      username
    }
  }
`

export const getBuyingGroup = /* GraphQL */ `
  query GetBuyingGroup($id: String!) {
    getBuying_group(id: $id) {
      id
      idOrganization
      name
      description
      uidCMS
      pubblicationStartDate
      registrationStartDate
      participationStartDate
      choosingStartDate
      registrationEndDate
      confirmationStartDate
      resellerFlowStartDate
      pubblicationEndDate
      participationEndDate
      choosingEndDate
      confirmationEndDate
      resellerFlowEndDate
      maxChoosableVariants
      uniteChooseAndConfirm
      declination
      isOnlyUpselling
      GoodsOrServices {
        GoodOrService {
          name
          slug
        }
      }
    }
  }
`

export const getBuyingGroupAsParticipant = /* GraphQL */ `
  query GetBuyingGroup($id: String!) {
    getBuying_group(id: $id) {
      id
      idOrganization
      name
      description
      uidCMS
      pubblicationStartDate
      registrationStartDate
      participationStartDate
      choosingStartDate
      registrationEndDate
      confirmationStartDate
      resellerFlowStartDate
      pubblicationEndDate
      participationEndDate
      choosingEndDate
      confirmationEndDate
      resellerFlowEndDate
      maxChoosableVariants
      uniteChooseAndConfirm
      declination
      isOnlyUpselling
      GoodsOrServices {
        GoodOrService {
          name
          slug
          GoodOrServiceType {
            name
            slug
            description
            userChoosingCustomFieldsDef
            userConfirmationCustomFieldsDef
          }
        }
        Variants {
          id
          code
          name
          description
          maxQuantity
          discountAmountEach
          discountPercent
        }
      }
      GoodOrServiceVariants {
        id
        code
        name
        description
        maxQuantity
        discountAmountEach
        discountPercent
        GoodOrService {
          name
          slug
          GoodOrServiceType {
            name
            slug
            description
            userChoosingCustomFieldsDef
            userConfirmationCustomFieldsDef
          }
        }
      }
      Resellers {
        Organization {
          id
          name
          state
          city
        }
      }
    }
  }
`

export const getBuyingGroupAsReseller = /* GraphQL */ `
  query GetBuyingGroup($id: String!) {
    getBuying_group(id: $id) {
      id
      idOrganization
      name
      description
      uidCMS
      pubblicationStartDate
      registrationStartDate
      participationStartDate
      choosingStartDate
      registrationEndDate
      confirmationStartDate
      resellerFlowStartDate
      pubblicationEndDate
      participationEndDate
      choosingEndDate
      confirmationEndDate
      resellerFlowEndDate
      maxChoosableVariants
      uniteChooseAndConfirm
      declination
      isOnlyUpselling
      UsersInBuyingGroup {
        registrationDate
        participationDate
        choosingDate
        notInterestInBuying
        confirmationDate
        definitelyNotInterestedInBuying
        User {
          firstName
          lastName
          taxCode
          discountCode
          email
          mobile
          city
          state
          subscribedSince
          subscribedUntil
          identityId
          id
        }
        ChosedVariants {
          id
          Variant {
            id
            name
          }
          Reseller {
            id
            name
          }
          quantity
          dateChosed
          userChoosingCustomValues
          dateConfirmed
          userConfirmationCustomValues
          dateFinalization
          resellerSellingCustomValues
          dateCanceled
          cancellationPhase
          cancellationReason
          dateDelivered
          dateBilled
        }
      }
      GoodsOrServices {
        GoodOrService {
          name
          slug
          GoodOrServiceType {
            name
            slug
            description
            userChoosingCustomFieldsDef
            userConfirmationCustomFieldsDef
            resellerSellingCustomFieldsDef
          }
        }
        Variants {
          id
          code
          name
          description
          maxQuantity
          discountAmountEach
          discountPercent
        }
      }
      GoodOrServiceVariants {
        id
        code
        name
        description
        maxQuantity
        discountAmountEach
        discountPercent
        GoodOrService {
          name
          slug
          GoodOrServiceType {
            name
            slug
            description
            userChoosingCustomFieldsDef
            userConfirmationCustomFieldsDef
            resellerSellingCustomFieldsDef
          }
        }
      }
      Resellers {
        Organization {
          id
          name
          state
          city
        }
      }
    }
  }
`

export const searchUserInBuyingGroups = /* GraphQL */ `
  query SearchUserInBuyingGroups($user_in_buying_group: SearchUser_in_buying_groupInput) {
    searchUser_in_buying_groups(user_in_buying_group: $user_in_buying_group) {
      id
      idBuyingGroup
      idUser
      registrationDate
      participationDate
      choosingDate
      notInterestInBuying
      confirmationDate
      definitelyNotInterestedInBuying
      lastEmaiNotificationDate
      disableNotifications
      ChosedVariants {
        id
        idBuyingGroup
        idUser
        idVariant
        idReseller
        quantity
        dateChosed
        userChoosingCustomValues
        dateConfirmed
        userConfirmationCustomValues
        dateFinalization
        resellerSellingCustomValues
        totalSoldAmount
        dateCanceled
        cancellationPhase
        cancellationReason
      }
      User {
        firstName
        state
        mobile
        mobileVerified
      }
    }
  }
`

export const createChosedVariant = /* GraphQL */ `
  mutation CreateChosedVariant($createChosed_variantInput: CreateChosed_variantInput!) {
    createChosed_variant(createChosed_variantInput: $createChosed_variantInput) {
      id
    }
  }
`
export const updateChosedVariant = /* GraphQL */ `
  mutation UpdateChosedVariant($updateChosed_variantInput: UpdateChosed_variantInput!) {
    updateChosed_variant(updateChosed_variantInput: $updateChosed_variantInput) {
      id
    }
  }
`
