import React, { useState } from "react"
import { Title } from "../Layout/Components"
import { ColumnData } from "./DynamicRow"
import Icon from "../Layout/Components/Icon"
import WPContentLight from "../wordpress/WPContentLight"

type Props = {
  className?: string
  cols: ColumnData[]
}

const Accordion: React.FC<Props> = ({ className, cols }) => {
  const [currentCol, setCurrentCol] = useState(-1)

  return (
    <React.Fragment>
      <div className={`row row--center row--no-grid ${className}`}>
        <div className="accordion">
          {cols.map((col, i) => {
            // Avoid h1 titles
            col.title.type = col.title.type == "h1" ? "h3" : col.title.type
            return (
              <React.Fragment key={i}>
                <div
                  className={"accordion-menu " + (currentCol == i ? "active" : currentCol == -1 && i == 0 ? "first" : "")}
                  onClick={() => {
                    setCurrentCol(currentCol == i ? -1 : i)
                  }}
                >
                  {col.title.title}
                  {currentCol == i ? <Icon name="minus-circle" strokeWidth={2} /> : <Icon name="plus-circle" strokeWidth={2} />}
                </div>
                <div className={"accordion-content " + (currentCol == i ? "active" : currentCol == -1 && i == 0 ? "first" : "")}>
                  <Title data={col.title} />
                  {/* <div className="accordion-content-text" dangerouslySetInnerHTML={{ __html: col.content }} /> */}
                  <div className="accordion-content-text">
                    <WPContentLight content={col.content} />
                  </div>
                </div>
              </React.Fragment>
            )
          })}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Accordion
