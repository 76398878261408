import React from "react"
import { graphql } from "gatsby"

// Rows
import { Newsletter } from "./Newsletter"
import Testimonials, { TestimonialData } from "./Testimonials"
import Press, { PressArticleData } from "./Press"

// Components
import { Link } from "../i18n"
import { Title, Button, Image, ImageTypes, ImageData, iconSizeData } from "../Layout/Components"
import DynamicCol from "./DynamicCol"
import CommunityArticles, { PostData } from "./CommunityArticles"
import HeroSlider from "./HeroSlider"
import Hero from "./Hero"
import FAQ, { FaqData } from "./FAQ"
import AllFAQs from "./AllFAQs"
import Team, { PeopleData } from "./Team"
import Cars, { CarData } from "./Cars"
import BuyingGroups, { DBBuyingGroupData, WPBuyingGroupData } from "./BuyingGroups"
import Accordion from "./Accordion"

export type RowTypes =
  | "hero"
  | "hero-slider"
  | "full-image"
  | "standard"
  | "accordion"
  | "newsletter"
  | "press"
  | "testimonials"
  | "community"
  | "faq"
  | "allfaqs"
  | "team"
  | "buying-groups"
  | "cars"
export type ColumnTypes =
  | "standard"
  | "standard-form"
  | "images"
  | "quote"
  | "card-mondi"
  | "card-servizi"
  | "card-partner"
  | "card-info"
  | "card-numbers"
  | "spacer"

export type RowData = {
  enabled: boolean
  type: RowTypes
  background: "none" | "straight" | "inclined" | "inclined-top" | "inclined-bottom" | "arrow"
  backgroundColor: string
  className: string
  anchor: string
  articlesType: "all" | "related"
  articlesCount: 0 | 1 | 3 | 5
  cols: ColumnData[]
  faqs: FaqData[]
  people: PeopleData[]
  cars: {
    title: string
    selectedBrands: [{ slug: string }]
    selectedSegments: [{ slug: string }]
    selectedTechnologies: [{ slug: string }]
    selectedCars: CarData[]
  }
  groups: {
    title: string
    subtitle: string
    type: "all" | "specific"
    tags?: [{ slug: string }]
  }
}

export type ColumnData = {
  style: {
    type: ColumnTypes
    width: number
    valign: "top" | "center" | "bottom"
    halign: "left" | "center" | "right"
    variant: "none" | "academy" | "market" | "plus" | "r-ace"
  }
  className: string
  title: TitleData
  content: string
  buttons: ButtonData[]
  images: FullImageData[]
  videos: FullVideoData[]
  form: {
    formName: string
    fields: string[]
    defaultToTrueFields: string[]
    submitButtonText: string
  }
}

export type ButtonData = {
  link: LinkData
  type: "primary" | "secondary" | "ghost" | "white"
}

export type TitleData = {
  title: null | string
  type: "h1" | "h2" | "h3" | "h4"
  align: "left" | "center" | "right"
  superTitle: { title: string; color: string }
  subTitle: { title: string; color: string }
  link: LinkData
}

export type FullImageData = {
  file: null | ImageData
  type: ImageTypes
  styledColor: string
  icon: IconData
  link: LinkData
}

export type FullVideoData = {
  cdnVideo: string
  youtubeVideo: string
}

export type LinkData = null | {
  title: string
  url: string
  target: string
}

export type IconData = { iconType?: string; iconStyle?: string; icon: string; size?: iconSizeData }

type Props = {
  data: RowData
  graphQLData: any
}

export const DynamicRow: React.FC<Props> = ({ data: row, graphQLData }) => {
  if (!row.enabled) return null

  const pressArticles: PressArticleData[] = graphQLData.wordPress.pressArticles.nodes
  const testimonials: TestimonialData[] = graphQLData.wordPress.testimonials.nodes
  const relatedPosts: PostData[] = graphQLData.wordPress.relatedPosts.nodes
  const recentPosts: PostData[] = graphQLData.wordPress.recentPosts.nodes
  const allCars: CarData[] = graphQLData.wordPress.cars.nodes
  const wpBuyingGroups: WPBuyingGroupData[] = graphQLData.wordPress.buyingGroups.nodes
  const dbBuyingGroups: DBBuyingGroupData[] = graphQLData.appsync.searchBuying_groups

  const debug = <pre style={{ fontSize: "12px", lineHeight: "14px" }}>{JSON.stringify(row, null, 2)}</pre>

  const rowColorClass =
    row.background == "none"
      ? ""
      : row.backgroundColor == "#163df7"
      ? "row__background--blu-elettrico"
      : row.backgroundColor == "#C712AD"
      ? "row__background--colored row__background--academy"
      : row.backgroundColor == "#00AF56"
      ? "row__background--colored row__background--race"
      : row.backgroundColor == "#F4144E"
      ? "row__background--colored row__background--plus"
      : row.backgroundColor == "#F27F16"
      ? "row__background--colored row__background--market"
      : ""

  switch (row.type) {
    case "hero":
      return (
        <React.Fragment>
          {row.anchor ? <a id={row.anchor}></a> : null}
          <Hero data={row.cols} />
        </React.Fragment>
      )
    case "hero-slider":
      return (
        <React.Fragment>
          {row.anchor ? <a id={row.anchor}></a> : null}
          <HeroSlider data={row.cols} />
        </React.Fragment>
      )
    case "full-image":
      return row.cols?.length && row.cols[0].images?.length ? (
        <React.Fragment>
          {row.anchor ? <a id={row.anchor}></a> : null}
          <div className={`row row--full-image ${row.className}`}>
            <Image file={row.cols[0]?.images[0]?.file} type="cover" />
          </div>
        </React.Fragment>
      ) : null
    case "standard":
      return (
        <div className={`row row--full row__background--${row.background} ${row.className} ${rowColorClass}`}>
          {row.anchor ? <a id={row.anchor}></a> : null}
          <div className="row row--center row--gap-big">
            {row.cols.map((col, i) => (
              <DynamicCol data={col} key={i} />
            ))}
          </div>
        </div>
      )
    case "accordion":
      return (
        <React.Fragment>
          {row.anchor ? <a id={row.anchor}></a> : null}
          <div className={`row row--full row__background--${row.background} ${rowColorClass}`}>
            <Accordion cols={row.cols} className={row.className} />
          </div>
        </React.Fragment>
      )
    case "newsletter":
      return (
        <React.Fragment>
          {row.anchor ? <a id={row.anchor}></a> : null}
          <Newsletter className={`row__background--${row.background} ${row.className} ${rowColorClass}`} />
        </React.Fragment>
      )
    case "testimonials":
      return (
        <React.Fragment>
          {row.anchor ? <a id={row.anchor}></a> : null}
          <Testimonials testimonials={testimonials} className={`row__background--${row.background} ${row.className} ${rowColorClass}`} />
        </React.Fragment>
      )
    case "press":
      return (
        <React.Fragment>
          {row.anchor ? <a id={row.anchor}></a> : null}
          <Press pressArticles={pressArticles} className={`row__background--${row.background} ${row.className} ${rowColorClass}`} />
        </React.Fragment>
      )
    case "faq":
      return (
        <React.Fragment>
          {row.anchor ? <a id={row.anchor}></a> : null}
          <FAQ faqs={row.faqs} className={`row__background--${row.background} ${row.className} ${rowColorClass}`} />
        </React.Fragment>
      )
    case "allfaqs":
      return (
        <React.Fragment>
          {row.anchor ? <a id={row.anchor}></a> : null}
          <AllFAQs className={`row__background--${row.background} ${row.className} ${rowColorClass}`} />
        </React.Fragment>
      )
    case "team":
      return (
        <React.Fragment>
          {row.anchor ? <a id={row.anchor}></a> : null}
          <Team people={row.people} className={`row__background--${row.background} ${row.className} ${rowColorClass}`} />
        </React.Fragment>
      )
    case "cars":
      //console.log("Row Cars: ", row.cars)
      return (
        <React.Fragment>
          {row.anchor ? <a id={row.anchor}></a> : null}
          <Cars
            title={row.cars.title}
            className={`row__background--${row.background} ${row.className} ${rowColorClass}`}
            cars={row.cars.selectedCars.length ? row.cars.selectedCars : allCars}
            selectedBrands={row.cars.selectedBrands.map((v) => v.slug)}
            selectedSegments={row.cars.selectedSegments.map((v) => v.slug)}
            selectedTechnologies={row.cars.selectedTechnologies.map((v) => v.slug)}
          />
        </React.Fragment>
      )
    case "buying-groups":
      return (
        <React.Fragment>
          {row.anchor ? <a id={row.anchor}></a> : null}
          <BuyingGroups
            title={row.groups.title}
            subtitle={row.groups.subtitle}
            className={`row__background--${row.background} ${row.className} ${rowColorClass}`}
            buyingGroupsType={row.groups.type}
            buyingGroupsTags={row.groups.tags.map((v) => v.slug)}
            wpBuyingGroups={wpBuyingGroups}
            dbBuyingGroups={dbBuyingGroups}
          />
        </React.Fragment>
      )
    case "community":
      return (
        <React.Fragment>
          {row.anchor ? <a id={row.anchor}></a> : null}
          <CommunityArticles
            relatedPosts={relatedPosts}
            allPosts={recentPosts}
            articlesType={row.articlesType}
            articlesCount={row.articlesCount}
            className={`row__background--${row.background} ${row.className} ${rowColorClass}`}
          />
        </React.Fragment>
      )
    default:
      return (
        <React.Fragment>
          <div className="row row--full ">
            <div className="row row--center row--no-grid">{debug}</div>
          </div>
        </React.Fragment>
      )
  }
}

export default DynamicRow

// Query fragment to include in graphql for fetching row data from Wordpress
export const query = graphql`
  fragment pageAccessoryData on Wordpress {
    ...pressArticles
    ...relatedTestimonials
    ...relatedPosts
    ...recentPosts
    ...carsData
    ...buyingGroupsData
  }
  fragment buyingGroupStep0Rows on Wordpress_BuyingGroup_Details_rowsStep0 {
    enabled
    type
    background
    backgroundColor
    className
    anchor
    articlesType
    articlesCount
    cols {
      style {
        type
        width
        valign
        halign
        variant
      }
      className
      title {
        title
        type
        align
        superTitle {
          title
          color
        }
        subTitle {
          title
          color
        }
        link {
          title
          url
          target
        }
      }
      content
      buttons {
        type
        link {
          title
          url
          target
        }
      }
      images {
        file {
          ...imageData
        }
        type
        styledColor
        icon {
          icon
          iconStyle
          iconType
        }
        link {
          title
          url
          target
        }
      }
      videos {
        cdnVideo
        youtubeVideo
      }
      form {
        formName
        fields
        defaultToTrueFields
        submitButtonText
      }
    }
    faqs {
      ... on Wordpress_Faq {
        title
        details {
          answer
        }
      }
    }
    people {
      ... on Wordpress_Person {
        ...person
      }
    }
    cars {
      title
      selectedBrands {
        slug
      }
      selectedSegments {
        slug
      }
      selectedTechnologies {
        slug
      }
      selectedCars {
        ... on Wordpress_Car {
          ...carData
        }
      }
    }
    groups {
      title
      subtitle
      type
      tags {
        slug
      }
    }
  }
  fragment buyingGroupStep1Rows on Wordpress_BuyingGroup_Details_rowsStep1 {
    enabled
    type
    background
    backgroundColor
    className
    anchor
    articlesType
    articlesCount
    cols {
      style {
        type
        width
        valign
        halign
        variant
      }
      className
      title {
        title
        type
        align
        superTitle {
          title
          color
        }
        subTitle {
          title
          color
        }
        link {
          title
          url
          target
        }
      }
      content
      buttons {
        type
        link {
          title
          url
          target
        }
      }
      images {
        file {
          ...imageData
        }
        type
        styledColor
        icon {
          icon
          iconStyle
          iconType
        }
        link {
          title
          url
          target
        }
      }
      videos {
        cdnVideo
        youtubeVideo
      }
      form {
        formName
        fields
        defaultToTrueFields
        submitButtonText
      }
    }
    faqs {
      ... on Wordpress_Faq {
        title
        details {
          answer
        }
      }
    }
    people {
      ... on Wordpress_Person {
        ...person
      }
    }
    cars {
      title
      selectedBrands {
        slug
      }
      selectedSegments {
        slug
      }
      selectedTechnologies {
        slug
      }
      selectedCars {
        ... on Wordpress_Car {
          ...carData
        }
      }
    }
    groups {
      title
      subtitle
      type
      tags {
        slug
      }
    }
  }
  fragment buyingGroupStep2Rows on Wordpress_BuyingGroup_Details_rowsStep2 {
    enabled
    type
    background
    backgroundColor
    className
    anchor
    articlesType
    articlesCount
    cols {
      style {
        type
        width
        valign
        halign
        variant
      }
      className
      title {
        title
        type
        align
        superTitle {
          title
          color
        }
        subTitle {
          title
          color
        }
        link {
          title
          url
          target
        }
      }
      content
      buttons {
        type
        link {
          title
          url
          target
        }
      }
      images {
        file {
          ...imageData
        }
        type
        styledColor
        icon {
          icon
          iconStyle
          iconType
        }
        link {
          title
          url
          target
        }
      }
      videos {
        cdnVideo
        youtubeVideo
      }
      form {
        formName
        fields
        defaultToTrueFields
        submitButtonText
      }
    }
    faqs {
      ... on Wordpress_Faq {
        title
        details {
          answer
        }
      }
    }
    people {
      ... on Wordpress_Person {
        ...person
      }
    }
    cars {
      title
      selectedBrands {
        slug
      }
      selectedSegments {
        slug
      }
      selectedTechnologies {
        slug
      }
      selectedCars {
        ... on Wordpress_Car {
          ...carData
        }
      }
    }
    groups {
      title
      subtitle
      type
      tags {
        slug
      }
    }
  }
  fragment buyingGroupStep3Rows on Wordpress_BuyingGroup_Details_rowsStep3 {
    enabled
    type
    background
    backgroundColor
    className
    anchor
    articlesType
    articlesCount
    cols {
      style {
        type
        width
        valign
        halign
        variant
      }
      className
      title {
        title
        type
        align
        superTitle {
          title
          color
        }
        subTitle {
          title
          color
        }
        link {
          title
          url
          target
        }
      }
      content
      buttons {
        type
        link {
          title
          url
          target
        }
      }
      images {
        file {
          ...imageData
        }
        type
        styledColor
        icon {
          icon
          iconStyle
          iconType
        }
        link {
          title
          url
          target
        }
      }
      videos {
        cdnVideo
        youtubeVideo
      }
      form {
        formName
        fields
        defaultToTrueFields
        submitButtonText
      }
    }
    faqs {
      ... on Wordpress_Faq {
        title
        details {
          answer
        }
      }
    }
    people {
      ... on Wordpress_Person {
        ...person
      }
    }
    cars {
      title
      selectedBrands {
        slug
      }
      selectedSegments {
        slug
      }
      selectedTechnologies {
        slug
      }
      selectedCars {
        ... on Wordpress_Car {
          ...carData
        }
      }
    }
    groups {
      title
      subtitle
      type
      tags {
        slug
      }
    }
  }
  fragment carsRow on Wordpress_Car_Details_rows {
    enabled
    type
    background
    backgroundColor
    className
    anchor
    articlesType
    articlesCount
    cols {
      style {
        type
        width
        valign
        halign
        variant
      }
      className
      title {
        title
        type
        align
        superTitle {
          title
          color
        }
        subTitle {
          title
          color
        }
        link {
          title
          url
          target
        }
      }
      content
      buttons {
        type
        link {
          title
          url
          target
        }
      }
      images {
        file {
          ...imageData
        }
        type
        styledColor
        icon {
          icon
          iconStyle
          iconType
        }
        link {
          title
          url
          target
        }
      }
      videos {
        cdnVideo
        youtubeVideo
      }
      form {
        formName
        fields
        defaultToTrueFields
        submitButtonText
      }
    }
    faqs {
      ... on Wordpress_Faq {
        title
        details {
          answer
        }
      }
    }
    people {
      ... on Wordpress_Person {
        ...person
      }
    }
    cars {
      title
      selectedBrands {
        slug
      }
      selectedSegments {
        slug
      }
      selectedTechnologies {
        slug
      }
      selectedCars {
        ... on Wordpress_Car {
          ...carData
        }
      }
    }
    groups {
      title
      subtitle
      type
      tags {
        slug
      }
    }
  }
  fragment pagesRow on Wordpress_Page_Details_rows {
    enabled
    type
    background
    backgroundColor
    className
    anchor
    articlesType
    articlesCount
    cols {
      style {
        type
        width
        valign
        halign
        variant
      }
      className
      title {
        title
        type
        align
        superTitle {
          title
          color
        }
        subTitle {
          title
          color
        }
        link {
          title
          url
          target
        }
      }
      content
      buttons {
        type
        link {
          title
          url
          target
        }
      }
      images {
        file {
          ...imageData
        }
        type
        styledColor
        icon {
          icon
          iconStyle
          iconType
        }
        link {
          title
          url
          target
        }
      }
      videos {
        cdnVideo
        youtubeVideo
      }
      form {
        formName
        fields
        defaultToTrueFields
        submitButtonText
      }
    }
    faqs {
      ... on Wordpress_Faq {
        title
        details {
          answer
        }
      }
    }
    people {
      ... on Wordpress_Person {
        ...person
      }
    }
    cars {
      title
      selectedBrands {
        slug
      }
      selectedSegments {
        slug
      }
      selectedTechnologies {
        slug
      }
      selectedCars {
        ... on Wordpress_Car {
          ...carData
        }
      }
    }
    groups {
      title
      subtitle
      type
      tags {
        slug
      }
    }
  }
  fragment postRow on Wordpress_Post_Details_rows {
    enabled
    type
    background
    backgroundColor
    className
    anchor
    articlesType
    articlesCount
    cols {
      style {
        type
        width
        valign
        halign
        variant
      }
      className
      title {
        title
        type
        align
        superTitle {
          title
          color
        }
        subTitle {
          title
          color
        }
        link {
          title
          url
          target
        }
      }
      content
      buttons {
        type
        link {
          title
          url
          target
        }
      }
      images {
        file {
          ...imageData
        }
        type
        styledColor
        icon {
          icon
          iconStyle
          iconType
        }
        link {
          title
          url
          target
        }
      }
      videos {
        cdnVideo
        youtubeVideo
      }
      form {
        formName
        fields
        defaultToTrueFields
        submitButtonText
      }
    }
    faqs {
      ... on Wordpress_Faq {
        title
        details {
          answer
        }
      }
    }
    people {
      ... on Wordpress_Person {
        ...person
      }
    }
    cars {
      title
      selectedBrands {
        slug
      }
      selectedSegments {
        slug
      }
      selectedTechnologies {
        slug
      }
      selectedCars {
        ... on Wordpress_Car {
          ...carData
        }
      }
    }
    groups {
      title
      subtitle
      type
      tags {
        slug
      }
    }
  }
`
