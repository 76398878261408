import React from "react"
import { graphql } from "gatsby"
import { Link, FormatDate, useI18next } from "../i18n"

import { Icon, Carousel, ImageData, Image, Button } from "../Layout/Components"
import sitemap from "../sitemap"

export type PeopleData = {
  title: string
  details: {
    bio: string
    ownedCars: string
    titolo: string
    photo: ImageData
  }
}

type Props = {
  className?: string
  people: PeopleData[]
}

export const Team: React.FC<Props> = ({ className, people }) => {
  const { t } = useI18next()
  //console.log("People: ", people)

  if (people.length == 0) return null

  return (
    <Carousel className={className}>
      {people.map((person, i) => (
        <div className="card__person" key={i}>
          <div className="card__person-cover">
            <Image file={person.details.photo} type="cover" className="card__person-image" />
          </div>
          <div className="card__person-content">
            {person.details.titolo ? <div className="card__person-title">{person.details.titolo}</div> : null}
            <h3 className="card__person-name" dangerouslySetInnerHTML={{ __html: person.title }}></h3>
            {person.details.ownedCars ? (
              <div className="card__person-cars">
                <div className="card__person-cars-title">{t("Le mie auto")}</div>
                <div dangerouslySetInnerHTML={{ __html: person.details.ownedCars }} />
              </div>
            ) : null}
          </div>
        </div>
      ))}
    </Carousel>
  )
}

export default Team

// Query fragment to include in graphql
export const query = graphql`
  fragment person on Wordpress_Person {
    id
    title
    details {
      bio
      ownedCars
      titolo
      photo {
        ...imageData
      }
    }
  }
`
