import React from "react"
import parse, { domToReact } from "html-react-parser"
import getByPath from "lodash/get"
import { Link } from "../i18n"
import { Themed } from "theme-ui"

type Props = {
  content: string
  className?: string
}

const WPContentLight: React.FC<Props> = ({ content, className }) => {
  if (content == null || content == undefined || content == "") return null

  const parseOptions = {
    replace: (domNode) => {
      // replaces links with <Link> element
      if (domNode.name === "a") {
        let className = getByPath(domNode, "attribs.class", "")
        return (
          <Themed.a as={Link} to={domNode.attribs.href} target={domNode.attribs.target} className={className}>
            {domToReact(domNode.children, parseOptions)}
          </Themed.a>
        )
      }
    },
  }

  const reactCcontent = parse(content, parseOptions)
  return <div className={className}>{reactCcontent}</div>
}

export default WPContentLight
