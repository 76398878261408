import React from "react"
import { graphql } from "gatsby"
import { Link, FormatDate, useI18next } from "../i18n"

import { Carousel, Image, ImageData, Icon } from "../Layout/Components"
import { Popover } from "antd"
import sitemap from "../sitemap"

export type TestimonialData = {
  id: string
  date: string
  title: string
  details: {
    testimonial: string
    car: {
      carAction: string
      carModel: string
    }
    boughCourse: string
    attendedRace: string
    country: string
    location: string
    photo: ImageData
  }
}

type Props = {
  className?: string
  testimonials: TestimonialData[]
}

export const Testimonials: React.FC<Props> = ({ className, testimonials }) => {
  const { t } = useI18next()

  if (testimonials.length == 0) return null

  // console.log("Testimonials: ", testimonials)

  return (
    <Carousel
      title="Testimonianze"
      more={
        <Link to={sitemap.testimonials.it} className="btn btn--ghost">
          Vedi tutte
        </Link>
      }
      className={className}
    >
      {testimonials.map((testimonial, i) => (
        <Link to={sitemap.testimonials.it + "#" + testimonial.id.replace("=", "")} className="card__testimonial" key={i}>
          <div className="card__testimonial-cover">
            <Image file={testimonial.details.photo} type="cover" className="card__testimonial-image" />
            <div className="card__testimonial-extra-info">
              {testimonial.details.car.carModel ? (
                <Popover title={t(testimonial.details.car.carAction)} content={testimonial.details.car.carModel} placement="top">
                  <div style={{ display: "inline", marginRight: "1rem" }}>
                    <Icon name="fas:car" />
                  </div>
                </Popover>
              ) : null}
              {testimonial.details.boughCourse ? (
                <Popover title={t("Corso frequentato")} content={testimonial.details.boughCourse} placement="top">
                  <div style={{ display: "inline", marginRight: "1rem" }}>
                    <Icon name="fas:graduation-cap" />
                  </div>
                </Popover>
              ) : null}
              {testimonial.details.attendedRace ? (
                <Popover title={t("Ecoverso r-ace frequentata")} content={testimonial.details.attendedRace} placement="top">
                  <div style={{ display: "inline", marginRight: "1rem" }}>
                    <Icon name="fas:trophy" />
                  </div>
                </Popover>
              ) : null}
            </div>
          </div>
          <div className="card__testimonial-content">
            <h3 className="card__testimonial-title">{testimonial.title}</h3>
            <div className="card__testimonial-description" dangerouslySetInnerHTML={{ __html: testimonial.details.testimonial.substring(0, 360) }}></div>
            <div className="card__testimonial-content-background"></div>
            <div className="card__testimonial-date">
              {testimonial.details.location}, <FormatDate date={testimonial.date} />
            </div>
          </div>
        </Link>
      ))}
    </Carousel>
  )
}

export default Testimonials

// Query fragment to include in graphql
export const query = graphql`
  fragment testimonial on Wordpress_Testimonial {
    id
    date
    title
    details {
      testimonial
      car {
        carAction
        carModel
      }
      boughCourse
      attendedRace
      country
      location
      photo {
        ...imageData
      }
    }
  }
  fragment relatedTestimonials on Wordpress {
    testimonials(where: { language: $languageCode, orderby: { field: DATE, order: DESC }, tagSlugIn: $tags }, first: 20) {
      nodes {
        ...testimonial
      }
    }
  }
`
