import React, { useState } from "react"
import Tabs from "../Layout/Components/Tabs"
import { ColumnData } from "./DynamicRow"
import { Image, Button, Title, Icon } from "../Layout/Components"

type Props = {
  data: ColumnData[]
}

const Hero: React.FC<Props> = ({ data: slides }) => {
  if (!Array.isArray(slides) || !slides[0]) return null

  const slide = slides[0]

  return (
    <div className="hero">
      <div className="hero__fixed">
        {slide.images[0] ? <Image file={slide.images[0].file} type="cover" className="hero__fixed__image" /> : null}
        {slide.className != "no-filter" ? <div className="hero__fixed__cover"></div> : null}
      </div>
      <div className={`hero__fixed__holder hero__fixed__holder--${slide.style.halign}`}>
        <Title data={slide.title} forceColor="#FFF" />
        <div className="hero__fixed__content" dangerouslySetInnerHTML={{ __html: slide.content }}></div>
        <div className="hero__fixed__cta">
          {slide.buttons ? slide.buttons.map((button, i) => <Button to="" data={{ ...button, type: "white" }} key={i} />) : null}
        </div>
      </div>
    </div>
  )
}

export default Hero
