import React from "react"
import { ColumnData } from "./DynamicRow"

import { Title, Button, Image, Video, Form } from "../Layout/Components"
import { Link } from "../i18n"
import WPContentLight from "../wordpress/WPContentLight"
import AnimatedNumber from "../Layout/Components/AnimatedNumber"

type Props = {
  data: ColumnData
}

export const DynamicCol: React.FC<Props> = ({ data: col }) => {
  const debug = <pre style={{ fontSize: "12px", lineHeight: "14px" }}>{JSON.stringify(col, null, 2)}</pre>

  switch (col.style.type) {
    case "standard-form":
    case "images":
    case "standard":
      const formDefaultToTrueFields = {}
      col.form.defaultToTrueFields.forEach((key) => (formDefaultToTrueFields[key] = true))
      return (
        <div className={`col--${col.style.width} col--va-${col.style.valign} ${col.className}`}>
          <Title data={col.title} />
          {/* <div dangerouslySetInnerHTML={{ __html: col.content }} /> */}
          <WPContentLight content={col.content} />
          {col.buttons ? col.buttons.map((button, i) => <Button to="" data={button} className="mt-s" key={i} />) : null}
          {col.images
            ? col.images.map((image, i) => (
                <Image file={image.file} type={image.type} styledColor={image.styledColor} icon={image.icon} link={image.link} key={i} />
              ))
            : null}
          {col.videos
            ? col.videos.map((video, i) => (
                <Video
                  cdnVideo={video.cdnVideo}
                  youtubeVideo={video.youtubeVideo}
                  type={video.type}
                  styledColor={video.styledColor}
                  icon={video.icon}
                  link={video.link}
                  key={i}
                />
              ))
            : null}

          {col.style.type == "standard-form" ? (
            <Form formName={col.form.formName} fields={col.form.fields} defaultToTrue={formDefaultToTrueFields} submitButtonText={col.form.submitButtonText} />
          ) : null}
        </div>
      )
      break
    case "quote":
      return (
        <div className={`quote col--${col.style.width} col--va-${col.style.valign} ${col.className}`}>
          {/* <div className="quote-content" dangerouslySetInnerHTML={{ __html: col.content }} /> */}
          <WPContentLight className="quote-content" content={col.content} />
        </div>
      )
      break
    case "card-mondi":
      return (
        <Link to={col.buttons && col.buttons[0] ? col.buttons[0].link?.url : ""} className={`col--${col.style.width} card__mondi mt-l mb-s ${col.className}`}>
          {col.images ? col.images.map((image, i) => <Image file={image.file} type="cover" className="card__mondi-image" key={i} />) : null}
          <div className="card__mondi-cover"></div>
          <h3 className="card__mondi-title" dangerouslySetInnerHTML={{ __html: col.title.title }}></h3>
          {col.buttons ? col.buttons.map((button, i) => <Button to={null} data={{ ...button, type: "primary" }} className="card__mondi-cta" key={i} />) : null}
        </Link>
      )
      break
    case "card-servizi":
      return (
        <Link
          to={col.buttons && col.buttons[0] ? col.buttons[0].link?.url : ""}
          className={`col--${col.style.width} card__servizi card__servizi--${col.style.variant} mt-l ${col.className}`}
        >
          <div className="card__servizi-cover">
            {col.images ? col.images.map((image, i) => <Image file={image.file} type="cover" className="card__servizi-image" key={i} />) : null}
          </div>
          <h3 className="card__servizi-title" dangerouslySetInnerHTML={{ __html: col.title.title }}></h3>
          {col.buttons ? col.buttons.map((button, i) => <Button to={null} data={{ ...button, type: "ghost" }} className="card__servizi-cta" key={i} />) : null}
        </Link>
      )
      break
    case "card-partner":
      const content = (
        <React.Fragment>
          {col.images ? col.images.map((image, i) => <Image file={image.file} type="cover" className="card__new-partner-image" key={i} />) : null}
          <div className="card__new-partner-content">
            <div>
              <h3 className="card__new-partner-title mb-xs" dangerouslySetInnerHTML={{ __html: col.title.title }}></h3>
              {/* <div className="card__new-partner-description" dangerouslySetInnerHTML={{ __html: col.content }}></div> */}
              <WPContentLight className="card__new-partner-description" content={col.content} />
            </div>
            <div>
              {col.buttons
                ? col.buttons.map((button, i) => <Button to={null} data={{ ...button, type: "ghost" }} className="card__new-partner-cta" key={i} />)
                : null}
            </div>
          </div>
        </React.Fragment>
      )
      return col.buttons && col.buttons[0] ? (
        <Link to={col.buttons[0].link?.url} className={`col--${col.style.width} card__new-partner mt-l ${col.className}`}>
          {content}
        </Link>
      ) : (
        <div className={`col--${col.style.width} card__new-partner mt-l ${col.className}`}>{content}</div>
      )

      break
    case "card-numbers":
      return (
        <div className={`col--${col.style.width} card__numbers mt-l ${col.className}`}>
          <div className="card__numbers-content">
            <div>
              <AnimatedNumber
                number={parseFloat(col.title.title)}
                prefix={col.title.superTitle.title}
                suffix={col.title.subTitle.title}
                className="card__numbers-title mb-xs"
              />
              {col.content && col.content != "" ? (
                <>
                  {/* <div className="card__numbers-description" dangerouslySetInnerHTML={{ __html: col.content }}></div> */}
                  <WPContentLight className="card__numbers-description" content={col.content} />
                </>
              ) : null}
              {col.buttons && col.buttons.length ? (
                <div>
                  {col.buttons ? col.buttons.map((button, i) => <Button to="" data={{ ...button, type: "ghost" }} className="card__info-cta" key={i} />) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )
      break
    case "card-info":
      return (
        <div className={`col--${col.style.width} card__info mt-l ${col.className}`}>
          {col.images ? col.images.map((image, i) => <Image file={image.file} type="cover" className="card__info-image" key={i} />) : null}
          <div className="card__info-content">
            <div>
              <div className="card__info-supertitle" dangerouslySetInnerHTML={{ __html: col.title.superTitle.title }}></div>
              <h3 className="card__info-title mb-xs" dangerouslySetInnerHTML={{ __html: col.title.title }}></h3>
              {col.content && col.content != "" ? (
                <>
                  {/* <div className="card__info-description" dangerouslySetInnerHTML={{ __html: col.content }}></div> */}
                  <WPContentLight className="card__info-description" content={col.content} />
                </>
              ) : null}
              {col.buttons && col.buttons.length ? (
                <div>
                  {col.buttons ? col.buttons.map((button, i) => <Button to="" data={{ ...button, type: "ghost" }} className="card__info-cta" key={i} />) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )
      break
    case "spacer":
      return <div className={`col--${col.style.width} col--spacer ${col.className}`}></div>
      break
    default:
      return (
        <React.Fragment>
          <div className="col--12">{debug}</div>
        </React.Fragment>
      )
      break
  }
}

export default DynamicCol
