import React, { useEffect, useState } from "react"

import { useI18next, Link } from "../i18n"
import { Image, ImageData, Icon, Carousel } from "../Layout/Components"
import { graphql } from "gatsby"
import { BrandData } from "./Cars"
import { isFuture, isPast, parseISO } from "date-fns"
import { differenceInCalendarDays } from "date-fns/esm"

export type DBBuyingGroupData = {
  name: string
  uidCMS: string
  pubblicationStartDate: string
  pubblicationEndDate: string
  registrationStartDate: string
  registrationEndDate: string
  confirmationEndDate: string
}
export type WPBuyingGroupData = {
  title: string
  brands: {
    nodes: BrandData[]
  }
  tags: {
    nodes: { slug: string }[]
  }
  details: {
    uid: string
    imageHero: ImageData
  }
}

type Props = {
  buyingGroupsType: "all" | "specific"
  buyingGroupsTags?: string[]
  dbBuyingGroups: DBBuyingGroupData[]
  wpBuyingGroups: WPBuyingGroupData[]
  display?: "list" | "carousel"
  className?: string
  title?: string
  subtitle?: string
}

const BuyingGroups: React.FC<Props> = ({ buyingGroupsType, buyingGroupsTags, dbBuyingGroups, wpBuyingGroups, display, className, title, subtitle }) => {
  const { t } = useI18next()

  const [shownGroups, setShownGroups] = useState([])
  display = display || "carousel"
  title = title || t("Le nostre auto")

  //------------------------------------------------------------------------------------------------
  // console.log("buyingGroupsType: ", buyingGroupsType)
  // console.log("buyingGroupsTags: ", buyingGroupsTags)
  // console.log("DBBuyingGroups: ", dbBuyingGroups)
  // console.log("WPBuyingGroups: ", wpBuyingGroups)
  //------------------------------------------------------------------------------------------------

  useEffect(() => {
    // Filter cars
    let tmpShownGroups = wpBuyingGroups.filter((group) => {
      if (buyingGroupsType == "all") return true

      return (group.tags.nodes as any).reduce((prev, curr) => {
        return prev || buyingGroupsTags.includes(curr.slug)
      }, false)
    })

    setShownGroups(tmpShownGroups)
  }, [dbBuyingGroups, wpBuyingGroups, buyingGroupsType, buyingGroupsTags])

  const renderedGroups = shownGroups.map((group, i) => {
    const dbGroup = dbBuyingGroups.find((g) => g.uidCMS == group.details.uid)
    let message: any = ""

    const pubblicationStartDate = dbGroup.pubblicationStartDate ? parseISO(dbGroup.pubblicationStartDate) : null
    const pubblicationEndDate = dbGroup.pubblicationEndDate ? parseISO(dbGroup.pubblicationEndDate) : null
    const registrationStartDate = dbGroup.registrationStartDate ? parseISO(dbGroup.registrationStartDate) : null
    const registrationEndDate = dbGroup.registrationEndDate ? parseISO(dbGroup.registrationEndDate) : null
    const confirmationEndDate = dbGroup.confirmationEndDate ? parseISO(dbGroup.confirmationEndDate) : null
    //console.log(pubblicationStartDate, pubblicationEndDate, registrationStartDate, registrationEndDate, confirmationEndDate)

    if (registrationStartDate == null) {
      message = "coming soon"
    } else if (isFuture(registrationStartDate)) {
      message = differenceInCalendarDays(registrationStartDate, new Date()) + " giorni all'apertura delle registrazioni"
    } else if (isFuture(registrationEndDate)) {
      message = differenceInCalendarDays(registrationEndDate, new Date()) + " giorni alla chiusura delle registrazioni"
    } else if (isPast(registrationEndDate) && isFuture(confirmationEndDate)) {
      message = (
        <span>
          registrazioni chiuse
          <br />
          {differenceInCalendarDays(confirmationEndDate, new Date())} giorni per acquistare
        </span>
      )
    } else if (isPast(confirmationEndDate)) {
      message = "chiuso"
    }

    return (
      <Link to={"group:" + group.details.uid} className="card__group" key={i}>
        <div className="card__group__container">
          <div className="card__group-cover">
            <Image file={group.details.imageHero} className="card__group-image" type="cover" />
          </div>
          <div className="card__group-brands">
            {group.brands.nodes.map((node) => (
              <Image file={node.details.logo} className="card__group-brands-logo" type="contain" />
            ))}
            {/* <Image file={group.brands.nodes[0]?.details.logo} className="card__group-brands-logo" type="contain" /> */}
          </div>
          <div className="card__group-content">
            <div className="card__group-title">{group.title}</div>
            <div className="card__group-text">{message}</div>
          </div>
          <button className="btn btn--primary card__group-cta">{t("Scopri di più")}</button>
        </div>
      </Link>
    )
  })

  switch (display) {
    case "list":
      return <div className="cars-list">{renderedGroups}</div>
    case "carousel":
      return (
        <Carousel
          title={title}
          subtitle={subtitle}
          more={
            <Link to="groups" className="btn btn--ghost">
              Vedi tutti
            </Link>
          }
          className={className}
          initialIndex={Math.min(1, renderedGroups.length - 1)}
        >
          {renderedGroups}
        </Carousel>
      )
  }
}

export default BuyingGroups

export const query = graphql`
  fragment dbBuyingGroupsData on Appsync {
    searchBuying_groups {
      name
      uidCMS
      pubblicationStartDate
      pubblicationEndDate
      registrationStartDate
      registrationEndDate
      confirmationEndDate
    }
  }
  fragment buyingGroupData on Wordpress_BuyingGroup {
    title
    details {
      uid
      imageHero {
        ...imageData
      }
    }
    tags {
      nodes {
        slug
      }
    }
    brands {
      nodes {
        name
        slug
        details {
          logo {
            ...imageData
          }
        }
      }
    }
  }
  fragment buyingGroupsData on Wordpress {
    buyingGroups(where: { language: $languageCode, in: $buyingGroupsWpIds }, first: 1000) {
      nodes {
        ...buyingGroupData
      }
    }
  }
`
