import React from "react"
import { graphql } from "gatsby"
import { Link, FormatDate } from "../i18n"

import { Icon, Carousel, ImageData, Image, Button } from "../Layout/Components"
import sitemap from "../sitemap"

export type PressArticleData = {
  date: string
  title: string
  details: {
    featuredImage: ImageData
    url: string
    excerpt: string
  }
  newspapers: {
    nodes: {
      slug: string
      name: string
      details: {
        logo: ImageData
      }
    }
  }
}

type Props = {
  className?: string
  pressArticles: PressArticleData[]
}

export const Press: React.FC<Props> = ({ className, pressArticles }) => {
  //console.log("Articles: ", pressArticles)

  if (pressArticles.length == 0) return null

  return (
    <Carousel
      title="Parlano di noi"
      more={
        <Link to={sitemap.press.it} className="btn btn--ghost">
          Vedi tutti
        </Link>
      }
      className={className}
    >
      {pressArticles.map((article, i) => (
        <Link to={article.details.url} target="_blank" className="card__press" key={i}>
          <div className="card__press-cover">
            <div className="card__press-image-cover"></div>
            <Image file={article.details.featuredImage} type="cover" className="card__press-image" />
            <Image file={article.newspapers.nodes[0]?.details.logo} type="contain" className="card__press-logo" />
          </div>
          <div className="card__press-content">
            <div className="card__press-date">
              <FormatDate date={article.date} />
            </div>
            {/* <h3
              className="card__press-newspaper"
              dangerouslySetInnerHTML={{ __html: article.newspapers.nodes[0]?.name }}
            ></h3> */}
            <h3 className="card__press-title" dangerouslySetInnerHTML={{ __html: article.title }}></h3>
            <p className="card__press-excerpt" dangerouslySetInnerHTML={{ __html: article.details.excerpt }}></p>
            <h3 className="card__press-title"></h3>
            <div className="card__press-content-background"></div>
            <Button to={null} external title="Visualizza" type="secondary" className="card__press-cta">
              <Icon name="external-link" className="btn__icon" />
            </Button>
          </div>
        </Link>
      ))}
    </Carousel>
  )
}

export default Press

// Query fragment to include in graphql
export const query = graphql`
  fragment pressArticle on Wordpress_PressArticle {
    date
    title
    details {
      featuredImage {
        ...imageData
      }
      url
      excerpt
    }
    newspapers {
      nodes {
        slug
        name
        details {
          logo {
            ...imageData
          }
        }
      }
    }
  }
  fragment pressArticles on Wordpress {
    pressArticles(where: { orderby: { field: DATE, order: DESC }, tagSlugIn: $tags }, first: 10) {
      nodes {
        ...pressArticle
      }
    }
  }
`
