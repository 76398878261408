import React, { useState } from "react"
import Tabs from "../Layout/Components/Tabs"
import { ColumnData } from "./DynamicRow"
import { Image, Button } from "../Layout/Components"

type Props = {
  data: ColumnData[]
}

const HeroSlider: React.FC<Props> = ({ data: slides }) => {
  const [activeId, setActiveId] = useState(0)

  if (!Array.isArray(slides) || slides.length == 0) return null

  return (
    <div className="hero">
      <div className="hero__slider slider">
        {slides.map((slide, idx) => {
          return slide.images?.length ? (
            <Image
              key={idx}
              file={slide.images[0]?.file}
              type="cover"
              className={"slider__image" + (activeId == idx ? " slider__image--active" : "")}
            />
          ) : null
        })}
        <div className="slider__cover"></div>
        <Tabs
          tabs={slides.map((slide) => slide.title.title)}
          onChange={(id) => {
            setActiveId(id)
          }}
          className="slider__nav"
        />
        <div className="slider__slides">
          {slides.map((slide, idx) => (
            <div className={"slider__slide" + (activeId == idx ? " slider__slide--active" : "")} key={idx}>
              <div className="slider__title" dangerouslySetInnerHTML={{ __html: slide.content }}></div>
              <div className="slider__cta">
                {slide.buttons
                  ? slide.buttons.map((button, i) => <Button to="" data={{ ...button, type: "white" }} key={i} />)
                  : null}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default HeroSlider
