import React, { useState, useEffect } from "react"

import { graphql, useStaticQuery } from "gatsby"

import { useTranslation, Link, FormatDate } from "../i18n"

import GridLayout from "../Layout/GridLayout"
import { Image, Icon, ImageData, Button } from "../Layout/Components"
import { Popover } from "antd"
import { TestimonialData } from "./Testimonials"
import FAQ from "./FAQ"
import { FaqData } from "./FAQ"

import { Seo } from "../seo"

type queryData = {
  wordPress: {
    faqCategories: {
      nodes: [
        {
          name
          details: {
            icon
            iconStyle
            iconType
          }
          faqs: {
            nodes: FaqData[]
          }
        }
      ]
    }
  }
}

type Props = {
  className?: string
}

const AllFAQs: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation([])

  const [faqCategories, setFaqCategories] = useState([])
  const [activeFaq, setActiveFaq] = useState(0)

  const data: queryData = useStaticQuery(graphql`
    query GetFAQs {
      wordPress {
        faqCategories(where: { orderby: DESCRIPTION }) {
          nodes {
            name
            details {
              icon
              iconStyle
              iconType
            }
            faqs(where: { orderby: { field: DATE, order: ASC } }, first: 1000) {
              nodes {
                title
                details {
                  answer
                }
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    setFaqCategories(data.wordPress.faqCategories.nodes)
    console.log("FAQS: ", faqCategories)
  }, [data])

  return (
    <div className={`row row--full ${className} faqs`}>
      <div className="row row--center row--no-grid pt-none pb-s">
        <div className="faqs-categories">
          {faqCategories?.map((faqCategory, i) => (
            <div
              className={"faqs-categories-button" + (activeFaq == i ? " faqs-categories-button--active" : "")}
              onClick={() => {
                setActiveFaq(i)
              }}
            >
              <Icon icon={faqCategory.details} />
              {faqCategory.name}
            </div>
          ))}
        </div>
      </div>
      <FAQ faqs={faqCategories[activeFaq]?.faqs.nodes} className="pt-none" />
    </div>
  )
}

export default AllFAQs
