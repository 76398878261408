import React, { useContext, useEffect, useState } from "react"

import { useTranslation } from "../i18n"
import { useSelector, useDispatch } from "react-redux"
import * as actions from "../store/actions"
import * as actionTypes from "../store/actions/actionTypes"
import { useMutation } from "@apollo/client"
import { UPDATE_PERSONAL_DETAILS } from "../graphql.my/queries"
import gql from "graphql-tag"

import { getAppSyncClientContext } from "../ApolloWrapper"

import { firstName, email, readPrivacy } from "../forms/fields/user"
import FormGenerator from "../Layout/Forms/FormGenerator"
import { Loading } from "../Layout/Components"
import { createFormSubmission } from "../graphql/mutations"

let subscribeFormSetValues = null
let subscribeFormReset = null

type Props = {
  className?: string
}

export const Newsletter: React.FC<Props> = ({ className }) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
  const userId = useSelector((state) => state.auth.payload.userData?.userId)
  const storedEmail = useSelector((state) => state.auth.payload.userData?.email)
  const storedFirstName = useSelector((state) => state.auth.payload.userData?.firstName)
  const acptNewsletter = useSelector((state) => state.auth.payload.userData?.acptNewsletter == "1")

  const [loading, setLoading] = useState(false)

  // --- Update logged in user ------------------------------------------------------------------------------------------
  const [updateUser, { error: mutationError }] = useMutation(gql(UPDATE_PERSONAL_DETAILS))

  if (mutationError) {
    dispatch(actions.notificationAdd({ message: t("API Error"), variant: "DangerIcon" }))
    console.log("API Error", mutationError)
    return null
  }

  // --- NEW formSubmission ---------------------------------------------------------------------------------------------
  const getAppsyncClient = useContext(getAppSyncClientContext)
  const [submitForm, { error: formSubmissionError }] = useMutation(gql(createFormSubmission), {
    client: getAppsyncClient(isLoggedIn),
  })

  if (formSubmissionError) {
    dispatch(actions.notificationAdd({ message: t("Form Submission Error"), variant: "DangerIcon" }))
    console.log("Form Submission Error", formSubmissionError)
    return null
  }
  // --------------------------------------------------------------------------------------------------------------------

  const subscribeForm = {
    firstName: { ...firstName, disabled: isLoggedIn },
    email: { ...email, disabled: isLoggedIn },
    readPrivacy,
  }

  const onSubmit = (values, setSubmitting) => {
    if (isLoggedIn) {
      // If logged in: subscribe to newsletter
      updateUser({ variables: { acptNewsletter: 1, id: userId } }).then(() => {
        dispatch(actions.notificationAdd({ message: "Iscrizione avvenuta con successo", variant: "SmileIcon" }))
      })
    } else {
      // todo submit form

      console.log("Form submission: ", values)
      submitForm({
        variables: { createForm_submissionInput: { jsonData: JSON.stringify(values), formName: "newsletter" } },
      }).then(() => {
        dispatch(
          actions.notificationAdd({
            message:
              "Iscrizione alla newsletter avvenuta con successo. Se non eri già iscritto riceverai a breve una mail con un link di conferma su cui cliccare.",
            variant: "SmileIcon",
            duration: 30,
          })
        )
        setSubmitting(false)
        subscribeFormReset()
      })
    }
  }

  // Do not render if already subscribed
  if (isLoggedIn && acptNewsletter) return null

  return (
    <div className={`row row--full row__background--arrow ${className}`}>
      <Loading active={loading} fixed></Loading>
      <div className="row row--center row--gap-big">
        <div className="col--5">
          <h2 className="heading heading-title">Registrati alla nostra newsletter</h2>
          <p className="heading-subtitle">
            Resta aggiornato su tutte le novità che ruotano attorno al nostro Ecoverso: gruppi d’acquisto, corsi ed eventi. Puoi cancellarti in qualsiasi
            momento.
          </p>
        </div>
        <div className="col--1"></div>
        <div className="col--5">
          <React.Fragment>
            <FormGenerator
              className="btn--fullwidth"
              properties={subscribeForm}
              values={{ firstName: storedFirstName, email: storedEmail }}
              hideCancelButton
              submitButtonText="Effettua l'iscrizione"
              onSubmit={onSubmit}
              bindSetValues={(callback) => (subscribeFormSetValues = callback)}
              bindResertForm={(callback) => (subscribeFormReset = callback)}
            />
          </React.Fragment>
        </div>
      </div>
    </div>
  )
}

export default Newsletter
